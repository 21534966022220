'use strict'

###*
 # @ngdoc service
 # @name users.factory:User

 # @description

###
angular
  .module 'users'
  .factory 'TenantUserManager', [
    '$rootScope'
    'MundoDefaultManager'
    'UserManager'
    'UserRoleManager'
    'TenantManager'
    'UserContextGroupManager'
    'UserContextManager'
    'ConfirmBox'
    '$state'
    (
      $rootScope
      MundoDefaultManager
      UserManager
      UserRoleManager
      TenantManager
      UserContextGroupManager
      UserContextManager
      ConfirmBox
      $state
    ) ->
      UserBase =  new MundoDefaultManager()

      UserBase.setUrl('users')
      UserBase.setNewObject(['username', 'email', 'enabled'])
      UserBase.setUpdateObject(['username', 'email', 'enabled', 'plainPassword'])
      UserBase.setObject('userContext', ['user', 'roles', 'userContextGroups', 'tenant'])
      UserBase.setSearchFields(['user.username', 'user.email', 'user.enabled'])

      UserBase.defaultQuery.sort = 'user.username'

      UserBase.delete = (id) ->
        @transport.one('user_contexts', id).remove()

      UserBase.new = (data) ->
        console.log data.enabled
        #check if user already exists but not in this context
        userExistsInOtherContext(data.email)
          .then(
            (result) =>
              roles =  data.roles
              data.roles = undefined
              groups = data.userContextGroups
              data.userContextGroups = undefined
              tenants = @extractIds(data.tenants)
              tenants = if tenants? then tenants else []
              tenants.push $rootScope.user.activeUserContext.tenant.id
              data.tenants = undefined
              #link the existing user to the current tennant
              if result?
                title = "Link this user to the current tennant?"
                message = "The email address '#{data.email}' is already in use by the user '#{result.username}'.\n"
                message += "Are you sure you want to link this user to the current tennant"
                message += " (#{$rootScope.user.activeUserContext.tenant.label})?"
                ConfirmBox.showConfirmDialog(title, message)
                  .then(=>
                    userContextData = {}
                    userContextData.user = result.id
                    userContextData.tenant = $rootScope.user.activeUserContext.tenant.id
                    userContextData.roles = @extractIds(roles)
                    userContextData.userContextGroups = @extractIds(groups)
                    @transport.all('user_contexts').post(_.pick(userContextData, @getObject('userContext')))
                      .then(-> $state.reload())
                  )
              #else just proceed creating the user
              else
                @transport.all('users').post(_.pick(data, @getNewObject())).then (userObject) =>
                  for tenant in tenants
                    userContextData = {}
                    userContextData.user = userObject.id
                    userContextData.tenant = tenant
                    if tenant == $rootScope.user.activeUserContext.tenant.id
                      userContextData.roles = @extractIds(roles)
                      userContextData.userContextGroups = @extractIds(groups)
                    @transport.all('user_contexts').post(_.pick(userContextData, @getObject('userContext')))
          )

      UserBase.submit = (data, entity) ->
        @setUrl('users')
        if entity
          roles =  data.roles
          data.roles = undefined
          groups = data.userContextGroups
          data.userContextGroups = undefined
          @update(entity.user.id, data)
          userContextData = {}
          userContextData.user = entity.user.id
          userContextData.roles = @extractIds(roles)
          userContextData.userContextGroups = @extractIds(groups)
          @transport.all('user_contexts').one(entity.id).customPUT(_.pick(userContextData, @getObject('userContext')))
        else
          @new(data)

      UserBase.getNewForm = ->
        [
          key: 'username'
          name: 'username'
          type: 'input'
          templateOptions:
            label: 'Username'
            placeholder: 'johndoe'
            required: true
            description: 'Descriptive text'
        ,
          key: 'email'
          name: 'email'
          type: 'input'
          templateOptions:
            type: 'email'
            label: 'Email address'
            placeholder: 'Enter email'
            required: true
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          templateOptions:
            label: 'User Groups'
            placeholder: 'User Groups'
            multiple: true,
            autocompleteLabel : 'add  or remove a user group'
            labelProp: "label"
            valueProp: "id"
            options: UserContextGroupManager.getList().$object
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        # ,
        #   key: 'tenants'
        #   name: 'tenants'
        #   type: 'modelchips'
        #   templateOptions:
        #     label: 'Also add to child tenants'
        #     placeholder: 'tenants'
        #     multiple: true,
        #     labelProp: "label"
        #     autocompleteLabel : 'add  or remove a child tenant'
        #     valueProp: "id"
        #     options: TenantManager.getFullList()
        #       .then((tenants) -> return _.filter tenants ,(t) -> t.id != $rootScope.user.activeUserContext.tenant.id)
        ,
          key: 'enabled'
          name: 'enabled'
          type: 'checkbox'
          templateOptions:
            label: 'enabled'
        ]

      UserBase.getEditForm = (data)->
        [
          key: 'username'
          name: 'username'
          type: 'input'
          defaultValue: data.user.username,
          templateOptions:
            label: 'Username'
            placeholder: 'johndoe'
            required: true
            description: 'Descriptive text'
        ,
          key: 'email'
          name: 'email'
          type: 'input'
          defaultValue: data.user.email,
          templateOptions:
            type: 'email'
            label: 'Email address'
            placeholder: 'Enter email'
            required: true
        ,
          key: 'plainPassword'
          name: 'plainPassword'
          type: 'input'
          defaultValue: data.user.plainPassword,
          templateOptions:
            type: 'password'
            label: 'Password'
        ,
          key: 'userContextGroups'
          name: 'userContextGroups'
          type: 'modelchips'
          defaultValue: data.userContextGroups
          templateOptions:
            label: 'User Groups'
            placeholder: 'User Groups'
            multiple: true,
            autocompleteLabel : 'add  or remove a user group'
            labelProp: "label"
            valueProp: "id"
            options: UserContextGroupManager.getList().$object
        ,
          key: 'roles'
          name: 'roles'
          type: 'modelchips'
          defaultValue: data.roles
          templateOptions:
            label: 'Roles'
            placeholder: 'roles'
            multiple: true,
            labelProp: "label"
            autocompleteLabel : 'add  or remove a role'
            valueProp: "id"
            options: UserRoleManager.getFullList().$object
        ,
          key: 'enabled'
          name: 'enabled'
          type: 'checkbox'
          defaultValue: data.user.enabled,
          templateOptions:
            label: 'enabled'
        ]

      UserBase.getListSchema = ->
        [
          key: 'user.username'
          title: 'datatable.label.name'
          sort: 'user.username'
        ,
          key: 'user.email'
          title: 'datatable.label.email'
          sort: 'user.email'
        ,
          key: 'user.enabled'
          title: 'datatable.label.enabled'
          sort: 'user.enabled'
          type: 'boolean'
        ,
          key: 'roles.label'
          title: 'datatable.label.roles'
          multiple: 'roles'
          hideInList: true
        ,
          key: 'userContextGroups.label'
          title: 'datatable.label.groups'
          multiple: 'userContextGroups'
          hideInList: true
        ]

      UserBase.getListDefault = UserBase.getList

      UserBase.getList = (query, loadParams) ->
        UserBase.setUrl('user_contexts')
        loadParams['filter[isGroup]'] = 'isGroup,FALSE'
        promise = UserBase.getListDefault(query, loadParams)

        return promise

      #permission needed to edit/delete an entity
      UserBase.editPermission = 'manage all MundoMosaUserBundle:User entities'

      #check if a user exist based on email address
      userExists = (email) ->
        UserManager.getFullList()
          .then(
            (userlist) ->
              user = _.filter userlist, (u) -> u.email == email
              user = if user.length? and user.length > 0 then user[0] else undefined
              return user
            (error) -> return undefined
          )

      #check if a user exists in other tennants but not in this one
      userExistsInOtherContext = (email) ->
        userExists(email)
          .then(
            (user) ->
              if user?
                currentTennant = $rootScope.user.activeUserContext.tenant.id
                existsInContext = false
                for context in user.userContexts
                  if context.tenant? and
                    context.tenant.id == currentTennant and
                    !context.deletedAt
                      existsInContext = true

                if existsInContext then return undefined else return user
              else
                return undefined
          )

      UserBase
  ]
